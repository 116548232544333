<template>
    <h2>Reorder Stock</h2>
    <!-- Inputs -->
    <div class="card">
        <div class="flex gap-6">
            <div class="card w-6">
                <!-- Select Items by -->
                <div class="flex align-items-center">
                    <div class="w-15rem text-lg font-medium">Select Items By:</div>
                    <Dropdown v-model="inputs.selectedItemSelectionMethod" :options="itemSelectionMethods"
                        optionLabel="name" placeholder="Select a method" @change="resetItemSelectionMethod" />

                    <IconField v-if="inputs.selectedItemSelectionMethod &&
                        inputs.selectedItemSelectionMethod.name == 'Item Search'
                    " class="">
                        <InputIcon class="pi pi-search" />
                        <InputText v-model="inputs.search" placeholder="Search item" />
                    </IconField>

                    <!-- Supplier Selection -->
                    <div v-if="inputs.selectedItemSelectionMethod &&
                        inputs.selectedItemSelectionMethod.name == 'Supplier'
                    ">
                        <AutoCompleteSupplier v-model="inputs.supplier" @onSupplierSelected="onSupplierSelected()">
                        </AutoCompleteSupplier>
                    </div>

                    <Button v-if="inputs.selectedItemSelectionMethod &&
                        (inputs.selectedItemSelectionMethod.name == 'Stock Group' || inputs.selectedItemSelectionMethod.name == 'Item Search')
                    " text class="ml-3" @click="resetItemSelectionMethod">Reset</Button>
                </div>

                <!-- Stock Group -->
                <SelectStockGroup v-if="inputs.selectedItemSelectionMethod &&
                    inputs.selectedItemSelectionMethod.name == 'Stock Group'"
                    v-model:selectedStockGroups="inputs.stock_groups"
                    :preselectedStockGroups="inputs.stock_groups" class="mt-4" />

                <!-- Set days to arrive -->
                <div class="flex align-items-center days-to-arrive mt-4">
                    <div class="flex flex-column w-15rem">
                        <div class=" text-lg font-medium">Days to Manufacture:</div>
                        <span class="text-md">After order</span>
                    </div>
                    <InputNumber v-model="inputs.daysToManufacture" mode="decimal" />
                    <div class="ml-3 text-lg font-medium" title="Estimated date items will be sent out by supplier.">
                        ETS: {{ moment().add(inputs.daysToManufacture, 'd').format(moment.HTML5_FMT.DATE) }}
                    </div>
                </div>

                <!-- Set days to arrive -->
                <div class="flex align-items-center days-to-arrive mt-4">
                    <div class="flex flex-column w-15rem">
                        <div class=" text-lg font-medium">Days for Shipment:</div>
                        <span class="text-md">After ship out</span>
                    </div>
                    <InputNumber v-model="inputs.daysForShipment" mode="decimal" />
                    <div class="ml-3 text-lg font-medium" title="Estimated date items will arrive at shop.">
                        ETA: {{ moment().add((inputs.daysToManufacture +
                            inputs.daysForShipment), 'd').format(moment.HTML5_FMT.DATE) }}
                    </div>
                </div>

                <!-- Set days of stock to order -->
                <div class="flex align-items-center days-of-stock mt-4">
                    <div class="flex flex-column w-15rem">
                        <div class=" text-lg font-medium">Days of Stock:</div>
                        <span class="text-md">After shipment arrive</span>
                    </div>
                    <InputNumber v-model="inputs.daysOfStock" mode="decimal" />
                </div>

                <!-- Set Max Stock End Days -->
                <div class="flex align-items-center mt-4">
                    <div class="flex flex-column w-15rem">
                        <div class=" text-lg font-medium">Max Stock End Days:</div>
                        <span class="text-md">Set 0 to show all</span>
                    </div>
                    <InputNumber v-model="inputs.maxStockEndDays" mode="decimal" />
                </div>

                <!-- Set if show 0 reorder quantity products -->
                <div class="flex align-items-center mt-4">
                    <div class="w-15rem text-lg font-medium">Show Reorder Qty 0 Products</div>
                    <InputSwitch v-model="inputs.showZeroReorderQty" />
                </div>

                <!-- Set if show discontinued products -->
                <div class="flex align-items-center mt-4">
                    <div class="w-15rem text-lg font-medium">Show Discontinued Products</div>
                    <InputSwitch v-model="filters.show_discontinued" />
                </div>
            </div>

            <!-- Inputs -->
            <div class="card w-6">
                <div class="flex mb-3">
                    <div class="flex flex-column w-20rem">
                        <div class="text-lg font-medium">Showed Items: </div>
                    </div>
                    <div class="text-lg">{{ items.length }}</div>
                </div>
                <div class="flex">
                    <div class="flex flex-column w-20rem">
                        <div class="text-lg font-medium">Hidden Items: </div>
                    </div>
                    <div class="text-lg">{{ summary.hiddenItemsCount }}</div>
                </div>
            </div>
        </div>
        <Button label="Generate Report(s)" class="p-button-raised mt-4" @click="generateReport" :loading="isLoading"
            :disabled="inputs.selectedItemSelectionMethod.name == 'Supplier' && inputs.supplier == null
                || inputs.selectedItemSelectionMethod.name == 'Item Search' && inputs.search.length < 3
                || inputs.selectedItemSelectionMethod.name == 'Stock Group' && inputs.stock_groups.length < 1" />

        <Message severity="success">Generated "Reorder QTY" ALREADY factored in orders from (PQ and PO). "Reorder QTY"
            is the
            quantity you need to TOP UP on top of the ones already ordered/on-the-way.</Message>
        <Message severity="error">PO PQ is from Dashboard PO PQ! SQL Accounting software PO and PQ will not be factored
            in!
        </Message>
    </div>

    <!-- Results -->
    <div id="card-result" class="card">
        <TabView>
            <TabPanel header="All Results">
                <DataTable :loading="isLoading" :value="items" responsiveLayout="scroll" scrollable
                    scrollHeight="calc(85vh - 180px)" ref="dt" size="small">
                    <template #header>
                        <div class="flex justify-content-left flex-column sm:flex-row align-items-center gap-2">
                            <ButtonCopyToExcel :rows="items" :disabled="items.length <= 0" type="reorder_stock">
                            </ButtonCopyToExcel>
                            <ButtonCopyToSql :rows="items" :disabled="items.length <= 0" type="reorder_stock">
                            </ButtonCopyToSql>
                        </div>
                    </template>
                    <Column field="code" header="Code" :sortable="true">
                        <template #body="{ data }">
                            <!-- Open in new tab -->
                            <a :href="'/items/' + data.code" target="_blank">{{ data.code }}</a>
                        </template>
                    </Column>
                    <Column header="Description" field="DESCRIPTION" :sortable="true">
                        <template #body="{ data }">
                            <div class="flex align-items-start">
                                <div>{{ data.DESCRIPTION }}
                                    <span v-if="data.days_in_stock < 30"
                                        v-tooltip="data.days_in_stock + ' days of data'" style="background-color: #ED1C24; color: white; padding:
                                    0.2rem; border-radius: 1rem;">
                                        <i class="pi pi-exclamation-triangle" style=" font-size: 0.8rem;"></i>
                                    </span>

                                    <span v-else-if="data.days_in_stock < 60"
                                        v-tooltip="data.days_in_stock + ' days of data'" style="background-color: #FF7F27; padding:
                                    0.2rem; border-radius: 1rem;"> <i class="pi pi-exclamation-triangle"
                                            style=" font-size: 0.8rem;"></i> </span>

                                    <span v-else-if="data.days_in_stock < 90"
                                        v-tooltip="data.days_in_stock + ' days of data'" style="background-color: #fbff0e; padding:
                                    0.2rem; border-radius: 1rem;"> <i class="pi pi-exclamation-triangle"
                                            style=" font-size: 0.8rem;"></i> </span>
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column field="DESCRIPTION2" header="Description2" style="display: none;"></Column>
                    <Column field="STOCKGROUP" header="Stock Group" :sortable="true">
                    </Column>
                    <Column field="BALSQTY" header="Qty (Bal.)" :sortable="true"></Column>
                    <Column field="qty_pq" header="Qty (PQ)" :sortable="true">
                        <template #body="{ data }">
                            <div v-if="data.qty_pq > 0" class="flex gap-1 align-items-center">
                                {{ data.qty_pq }}
                                <Button icon="pi pi-info-circle" @click="viewPurchases(data.code)" severity="secondary"
                                    text class="p-0 w-min" />
                            </div>
                        </template>
                    </Column>
                    <Column field="qty_po" header="Qty (PO)" :sortable="true">
                        <template #body="{ data }">
                            <div v-if="data.qty_po > 0" class="flex gap-1 align-items-center">
                                {{ data.qty_po }}
                                <Button icon="pi pi-info-circle" @click="viewPurchases(data.code)" severity="secondary"
                                    text class="p-0 w-min" />
                            </div>
                        </template>
                    </Column>
                    <Column field="generated_suggested_qty" header="Qty (Suggested)" :sortable="true"></Column>
                    <Column field="order_qty" header="Qty (Order)" :sortable="true">
                        <template #body="{ data }">
                            <InputNumber v-model="data.order_qty" class="w-fit" />
                        </template>
                    </Column>
                    <Column field="generated_avg_qty_sold_per_mth" header="Avg Qty Per Mth" :sortable="true">
                    </Column>
                    <Column field="qty_sold_year" header="Qty Sold (Y)" :sortable="true"></Column>
                    <Column field="qty_sold_half_year" header="Qty Sold (½ Y)" :sortable="true"></Column>
                    <Column field="REFPRICE" header="Price" :sortable="true"></Column>
                    <Column field="generated_avg_sales_per_mth" header="Avg Sales Per Mth" :sortable="true"></Column>
                    <Column field="stock_end_date" header="Stock End Date" :sortable="true">
                        <template #body="{ data }">
                            <div v-if="data.stock_end_date">
                                {{ moment(data.stock_end_date).format('YYYY-MM-DD') }} ({{ data.generated_stock_end_days
                                }} days)
                            </div>
                        </template>
                    </Column>
                    <Column field="days_in_stock" header="Days In Stock" :sortable="true"></Column>
                </DataTable>
            </TabPanel>
            <TabPanel header="Under Development...">
                Under Development...
            </TabPanel>
        </TabView>
    </div>

    <!-- Show View Purchases Dialog -->
    <Dialog v-model:visible="dialogViewPurchases.show" modal header="View Purchases" :style="{ width: '50rem' }">
        <div class="mb-4">
            <div class="font-medium text-xl">Purchase Requests</div>
            <DataTable :loading="dialogViewPurchases.isLoading" :value="dialogViewPurchases.purchase_requests"
                size="small">
                <Column field="date" header="Date"></Column>
                <Column field="doc_no" header="Doc No"></Column>
                <Column field="description" header="description"></Column>
                <Column field="qty" header="Qty"></Column>
                <Column field="qty_fulfilled" header="Qty Fulfilled"></Column>
                <Column field="qty_pending" header="Qty Pending"></Column>
            </DataTable>
        </div>
        <div>
            <div class="font-medium text-xl">Purchase Orders</div>
            <DataTable :loading="dialogViewPurchases.isLoading" :value="dialogViewPurchases.purchase_orders"
                size="small">
                <Column field="date" header="Date"></Column>
                <Column field="doc_no" header="Doc No"></Column>
                <Column field="description" header="Description"></Column>
                <Column field="qty_pending" header="Qty Pending"></Column>
            </DataTable>
        </div>
    </Dialog>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, onUnmounted } from 'vue';
import ProductService from '../service/ProductService';
// import { FilterMatchMode } from 'primevue/api';
import ButtonCopyToSql from '@/components/ButtonCopyToSql.vue';
import ButtonCopyToExcel from '@/components/ButtonCopyToExcel.vue';
import AutoCompleteSupplier from '@/components/AutoCompleteSupplier.vue';
import moment from 'moment';
import axios from "axios";
import { onBeforeRouteLeave } from 'vue-router';
import SelectStockGroup from '@/components/SelectStockGroup.vue';

const dt = ref();

// const stockGroupTablefilters = ref({
//     STOCKGROUP: { value: null, matchMode: FilterMatchMode.CONTAINS }
// })


const stockGroupTablefilters = ref({
})

const itemSelectionMethods = ref([
    { name: "Supplier" },
    { name: "Item Search" },
    { name: "Stock Group" },
]);

const isLoading = ref(false);
const filters = ref({ show_discontinued: false });
const inputs = ref({
    selectedItemSelectionMethod: { name: "Supplier" },
    search: null,
    stock_groups: [],
    supplier: null,
    estimatedShipmentDate: null,
    daysToManufacture: 7,
    daysForShipment: 30,
    daysOfStock: 90,
    maxStockEndDays: 0,
    showZeroReorderQty: false,
});

const query = ref({
    search: null,
    stock_groups: null,
    supplier_id: null,
    filters: null,
});

const summary = ref({
    hiddenItemsCount: 0,
});

const items = ref([]);

const productService = ref(null);

onMounted(() => {
    productService.value = new ProductService();
})

// Show popup confirm exit when closing tab
const handleBeforeUnload = (e) => {
    if (reportGenerated) {
        e.preventDefault();
        e.returnValue = '';
    }
}

onUnmounted(() => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
})

// Confirm popup when changing route or closing tab
onBeforeMount(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
})

onBeforeRouteLeave((to, from, next) => {
    if (!reportGenerated || confirm("You have unsaved changes! Are you sure you want to leave?")) {
        next();
    } else {
        next(false);
    }
})


const resetItemSelectionMethod = () => {
    inputs.value.search = '';
    inputs.value.stock_groups = [];
    inputs.value.supplier = null;
}

const onSupplierSelected = () => {
    inputs.value.daysForShipment = inputs.value.supplier.days_for_shipment;
    inputs.value.daysToManufacture = inputs.value.supplier.days_to_manufacture;
    inputs.value.daysOfStock = inputs.value.supplier.days_of_stock_to_keep;
}

const updateInputsToQuery = () => {
    query.value = {}

    if (inputs.value.search) {
        query.value.search = inputs.value.search;
    }

    if (inputs.value.stock_groups) {
        query.value.stock_groups = inputs.value.stock_groups.map((stockGroup) => stockGroup.STOCKGROUP)
    }

    if (inputs.value.supplier) {
        query.value.supplier_id = inputs.value.supplier.id;
    }

    // Iterate each filter and add to query
    let filtersString = ''
    for (const filterName in filters.value) {
        const value = filters.value[filterName];
        filtersString += `${filterName}-${value}`
    }
    if (filtersString != '') {
        query.value.filters = filtersString;
    }
}

// ------------------------------------ //
// --------- Dialog Purchases --------- //
// ------------------------------------ //
const dialogViewPurchases = ref({
    show: false,
    code: null,
    isLoading: false,
    purchase_requests: [],
    purchase_orders: [],
})

const viewPurchases = async (code) => {
    dialogViewPurchases.value.show = true;
    dialogViewPurchases.value.code = code;
    dialogViewPurchases.value.isLoading = true;


    await axios({
        method: "GET",
        url: "items/pending-purchases?code=" + code,
    }).then(
        (result) => {
            dialogViewPurchases.value.purchase_requests = result.data.purchase_requests;
            dialogViewPurchases.value.purchase_orders = result.data.purchase_orders;

            dialogViewPurchases.value.isLoading = false;
        },
        (error) => {
            dialogViewPurchases.value.isLoading = false;
            console.log(error.response.data);
        }
    );
}

// --------- Dialog Purchases --------- //
let reportGenerated = false;

const generateReport = async () => {
    reportGenerated = true;

    updateInputsToQuery();

    isLoading.value = true;
    const params = new URLSearchParams();
    if (query.value.search) {
        params.append("search", query.value.search);
    }

    if (query.value.stock_groups && query.value.stock_groups.length > 0) {
        console.log(query.value.stock_groups)
        params.append("stock_groups", query.value.stock_groups);
    }

    if (query.value.supplier_id) {
        params.append("supplier_id", query.value.supplier_id);
    }

    if (query.value.filters) {
        params.append("filters", query.value.filters);
    }

    params.append("export", true);

    await axios({
        method: "GET",
        url: "analysis/items",
        params: params,
    }).then(
        (result) => {
            // ---- Calculate daysToStockArrival ---- //
            const daysToStockArrival = inputs.value.daysToManufacture + inputs.value.daysForShipment;

            // ------------ End ------------ //

            let tempItems = result.data.items;
            // Add generated field to items
            tempItems.forEach((item) => {
                // Round to 1 decimal
                item.REFPRICE = parseFloat(item.REFPRICE).toFixed(2);

                if (item.last_sold_date !== null) {
                    // Get days between last sold days and today
                    const lastSoldDate = new Date(item.last_sold_date);
                    const today = new Date();
                    const diffTime = (today - lastSoldDate);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    item.generated_last_sold_days = diffDays;
                }

                if (item.stock_end_date !== null) {
                    // Get days between stock end date and today
                    const stockEndDate = new Date(item.stock_end_date);
                    const today = new Date();

                    let diffTime = stockEndDate - today;

                    if (diffTime < 0) {
                        diffTime = 0;
                    }

                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    item.generated_stock_end_days = diffDays;
                }

                item.median_days_between_sales = Math.round(item.median_days_between_sales * 10) / 10;

                // ------------ Calculate daysToStockArrival  ------------ //

                let balQtyOnArrival = item.BALSQTY - daysToStockArrival * (item.generated_avg_qty_sold_per_mth / 30.4);

                // If balQtyOnArrival is negative, set to 0
                if (balQtyOnArrival < 0) {
                    balQtyOnArrival = 0;
                }

                let reorderQty = (inputs.value.daysOfStock * parseFloat(item.generated_avg_qty_sold_per_mth)) / 30.4 - balQtyOnArrival;
                if (reorderQty < 0) {
                    reorderQty = 0;
                }
                item.generated_suggested_qty = Math.max(0, Math.round(reorderQty) - item.qty_pq - item.qty_po);
                item.order_qty = item.generated_suggested_qty;
                // ------------ End ------------ //
            });

            // Filter max stock end days
            if (inputs.value.maxStockEndDays != 0) {
                const tempItemsFilteredMaxDays = [];
                tempItems.forEach((item) => {
                    if (item.generated_stock_end_days <= inputs.value.maxStockEndDays) {
                        tempItemsFilteredMaxDays.push(item);
                    }
                });
                summary.value.hiddenItemsCount = tempItems.length - tempItemsFilteredMaxDays.length;
                tempItems = tempItemsFilteredMaxDays;
            }

            // Filter reorder qty 0
            if (!inputs.value.showZeroReorderQty) {
                const tempItemsFilteredZeroReorderQty = [];
                tempItems.forEach((item) => {
                    if (item.generated_suggested_qty > 0) {
                        tempItemsFilteredZeroReorderQty.push(item);
                    }
                });

                summary.value.hiddenItemsCount = summary.value.hiddenItemsCount + tempItems.length - tempItemsFilteredZeroReorderQty.length;
                tempItems = tempItemsFilteredZeroReorderQty;
            }

            items.value = tempItems;

            // Scroll to card-result
            const cardResult = document.getElementById("card-result");
            cardResult.scrollIntoView({ behavior: "smooth" });
        },
        (error) => {
            console.log(error.response.data);
        }
    );
    isLoading.value = false;
}
</script>

<style>
.stock-group-selection {
    width: 50%;
}

.secondary-text {
    font-size: 0.75em;
}
</style>
