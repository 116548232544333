<template>
    <div>
        <div class="flex gap-2">
            <Button @click="printBarcodeClicked()" style="height: 30px;" icon="pi pi-tag" raised severity="success"
                :loading="isLoading" label="Barcode"></Button>
            <SplitButton style="height: 30px;" icon="pi pi-print" raised severity="success" :loading="isLoading"
                label="Print" @click="printRefillGroup()" :model="printMenuItems" />
        </div>

        <Dialog v-model:visible="isVisibleHistoryDialog" modal header="Print History" :style="{ 'min-width': '50rem' }"
            :dismissableMask="true">
            <DataTable :value="historyPrints" tableStyle="min-width: 50rem">
                <Column field="created_at" header="Printed At" sortable>
                    <template #body="{ data }">
                        <div v-if="data.created_at">
                            <div
                                :class="moment().isSame(moment(data.created_at), 'day') ? 'text-green-700 font-medium' : ''">
                                <!-- print as format date - time - from now -->
                                {{ moment(data.created_at).format('DD-MMM-YYYY HH:mm:ss') }} ({{
                                    moment(data.created_at).fromNow() }})
                            </div>
                        </div>
                    </template>
                </Column>
                <Column field="item_count" header="No. Of Items" sortable></Column>
                <Column header="Actions" :sortable="true">
                    <template #body="{ data }">
                        <div class="flex gap-3">
                            <Button @click="printHistorical(data.id)" icon="pi pi-print" raised style="height: 30px;" />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </Dialog>

        <Dialog v-model:visible="isVisibleBarcodeDialog" modal header="Print Barcode"
            :style="{ 'width': 'calc(100vw - 45px)' }" :dismissableMask="true">
            <DataTable :value="historyPrints" tableStyle="min-width: 50rem">
                <Column field="created_at" header="Printed At" sortable>
                    <template #body="{ data }">
                        <div v-if="data.created_at">
                            <div
                                :class="moment().isSame(moment(data.created_at), 'day') ? 'text-green-700 font-medium' : ''">
                                <!-- print as format date - time - from now -->
                                {{ moment(data.created_at).format('DD-MMM-YYYY HH:mm:ss') }} ({{
                                    moment(data.created_at).fromNow() }})
                            </div>
                        </div>
                    </template>
                </Column>
                <Column field="item_count" header="No. Of Items" sortable></Column>
                <Column header="Actions" :sortable="true">
                    <template #body="{ data }">
                        <div class="flex gap-3">
                            <Button @click="selectPrintForBarcode(data)" icon="pi pi-caret-right" raised
                                style="height: 30px;" />
                        </div>
                    </template>
                </Column>
            </DataTable>

            <div v-if="currentBarcodeDialogStep == 2">
                <div class="flex justify-content-between mt-8 mb-3">
                    <div class="text-2xl font-medium">Input barcode print quantity</div>
                    <Button @click="copyXmlToClipboard" icon="pi pi-copy" label="Copy To SQL" />
                </div>
                <ag-grid-vue class="ag-theme-alpine" style="height: calc(100vh - 450px);" :columnDefs="columnDefs.value"
                    :rowData="selectedPrintForBarcode.items" :defaultColDef="defaultColDef" animateRows="true"
                    @grid-ready="onGridReady" :gridOptions="gridOptions" @first-data-rendered="onFirstDataRendered">
                </ag-grid-vue>
            </div>
        </Dialog>


    </div>

</template>
<script setup>
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { AgGridVue } from "ag-grid-vue3";  // the AG Grid Vue Component
const gridApi = ref(null); // Optional - for accessing Grid's API

import { useToast } from 'primevue/usetoast'
import { ref, reactive, defineProps, defineEmits } from "vue";
import moment from 'moment';
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';

const toast = useToast()

const props = defineProps({
    refill_group_id: Number,
})

const emit = defineEmits(['refillGroupPrinted']);

const isVisibleHistoryDialog = ref(false);
const isVisibleBarcodeDialog = ref(false);
const currentBarcodeDialogStep = ref(1);
const selectedPrintForBarcode = ref(null);

const historyPrints = ref([]);

const isLoading = ref(false);

const route = useRoute();

const printMenuItems = [
    {
        icon: 'pi pi-history',
        label: 'Print History',
        command: () => {
            printHistoryClicked();
        }
    },
    {
        separator: true
    }
];
const printHistoryClicked = async () => {
    // Get today's refill group print
    await axios({
        method: "GET",
        url: "refill-groups/" + props.refill_group_id + "/print-history",
    }).then((response) => {
        const historyPrintsFromBackend = response.data.refill_group_prints;

        const printsToShow = [];

        // Iterate each and append item_count
        historyPrintsFromBackend.forEach((print) => {
            print.item_count = print.items.length;

            if (print.item_count > 0) {
                printsToShow.push(print);
            }
        });

        historyPrints.value = printsToShow;
    });

    isVisibleHistoryDialog.value = true;
}
const printBarcodeClicked = async () => {
    // Get today's refill group print
    await axios({
        method: "GET",
        url: "refill-groups/" + props.refill_group_id + "/print-history",
    }).then((response) => {
        const historyPrintsFromBackend = response.data.refill_group_prints;

        const printsToShow = [];

        // Iterate each and append item_count
        historyPrintsFromBackend.forEach((print) => {
            print.item_count = print.items.length;

            if (print.item_count > 0) {
                printsToShow.push(print);
            }
        });

        historyPrints.value = printsToShow;
    });

    selectedPrintForBarcode.value = null;
    currentBarcodeDialogStep.value = 1;
    isVisibleBarcodeDialog.value = true;
}

const selectPrintForBarcode = (selectedPrint) => {
    selectedPrintForBarcode.value = selectedPrint;
    currentBarcodeDialogStep.value = 2;

    // update quantity_to_print to quantity_sold
    selectedPrintForBarcode.value.items.forEach((item) => {
        item.quantity_to_print = item.quantity_sold;
    });
}

const printRefillGroup = async () => {
    isLoading.value = true;
    // Get Base64 pdf data
    await axios({
        method: "GET",
        url: "refill-groups/" + props.refill_group_id + "/print",
        responseType: "blob",
    }).then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);

        // Print the pdf without opening new tab
        const iframe = document.createElement("iframe");
        iframe.style.display = "none"
        iframe.src = fileURL;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();

        emit('refillGroupPrinted', props.refill_group_id);
    });
    isLoading.value = false;
}

const printHistorical = async (printId) => {
    isLoading.value = true;
    // Get Base64 pdf data
    await axios({
        method: "GET",
        url: "refill-groups/prints/" + printId + "/print",
        responseType: "blob",
    }).then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);

        // Print the pdf without opening new tab
        const iframe = document.createElement("iframe");
        iframe.style.display = "none"
        iframe.src = fileURL;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
    });
    isLoading.value = false;
}

// ----------------------- For ag-grid ------ START ----------------------- //
// Each Column Definition results in one Column.
const columnDefs = reactive({
    value: [
        {
            field: "ST_ITEM_CODE",
            headerName: 'Item Code',
            minWidth: 80,
            maxWidth: 100,
            editable: false,
        },
        {
            field: "item_description",
            headerName: 'Item Description',
            editable: false,
        },
        {
            field: "remark1",
            headerName: 'Remark 1',
            editable: false,
            maxWidth: 250,
        },
        {
            field: "remark2",
            headerName: 'Remark 2',
            editable: false,
            maxWidth: 250,
        },
        {
            field: "refprice",
            headerName: 'Price',
            editable: false,
            maxWidth: 250,
        },
        {
            field: "quantity_to_print",
            headerName: 'Quantity',
            maxWidth: 110
        },

    ],
});

// DefaultColDef sets props common to all Columns
const defaultColDef = {
    sortable: true,
    flex: 1,
    editable: true,
    resizable: true,
    wrapText: false,
    autoHeight: true,
};

// Obtain API from grid's onGridReady event
const onGridReady = (params) => {
    gridApi.value = params.api;
};

const onCellValueChanged = async (editedRow) => {
    // editedRow.node.setDataValue(editedRow.colDef.field, editedRow.data['editedRow.colDef.field']);
    selectedPrintForBarcode.value.items = [...selectedPrintForBarcode.value.items];
};

const gridOptions = ref({
    stopEditingWhenCellsLoseFocus: true,
    undoRedoCellEditing: true, // enables undo / redo
    undoRedoCellEditingLimit: 5, // restricts the number of undo / redo steps to 5
    enableCellChangeFlash: true,  // enables flashing to help see cell changes
    enterNavigatesVerticallyAfterEdit: true,
    suppressScrollOnNewData: true,
    onCellValueChanged: onCellValueChanged,
    singleClickEdit: true,
});

const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
}

const escapeXml = (str) => {
    if (!str) return ''; // Handle null/undefined cases
    return str
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&apos;'); // Optional
};

const copyXmlToClipboard = () => {
    let xmlData = `<?xml version="1.0" standalone="yes"?>
<DATAPACKET Version="2.0">
  <METADATA>
    <FIELDS>
      <FIELD attrname="Code" fieldtype="string.uni" WIDTH="60"/>
      <FIELD attrname="Description" fieldtype="string.uni" WIDTH="400"/>
      <FIELD attrname="UOM" fieldtype="string.uni" WIDTH="20"/>
      <FIELD attrname="Barcode" fieldtype="string.uni" WIDTH="100"/>
      <FIELD attrname="RefPrice" fieldtype="fixedFMT" DECIMALS="8" WIDTH="18"/>
      <FIELD attrname="ItemType" fieldtype="string" WIDTH="1"/>
      <FIELD attrname="Remark1" fieldtype="string.uni" WIDTH="400"/>
      <FIELD attrname="Remark2" fieldtype="string.uni" WIDTH="400"/>
      <FIELD attrname="Copies" fieldtype="i4"/>
    </FIELDS>
    <PARAMS/>
  </METADATA>
  <ROWDATA>\n`;

    selectedPrintForBarcode.value.items.forEach(item => {
        if (item.quantity_to_print <= 0) return;
        xmlData += `    <ROW UOM="UNIT"
        Code="${escapeXml(item.ST_ITEM_CODE)}" 
        Barcode="${escapeXml(item.ST_ITEM_CODE)}" 
        Description="${escapeXml(item.item_description)}" 
        RefPrice="${escapeXml(item.refprice)}" 
        Remark1="${escapeXml(item.remark1 || '')}" 
        Remark2="${escapeXml(item.remark2 || '')}" 
        Copies="${item.quantity_to_print}"/>\n`;
    });

    xmlData += `  </ROWDATA>\n</DATAPACKET>`;

    // Copy to clipboard
    navigator.clipboard.writeText(xmlData)
        .then(() => {
            toast.add({ severity: 'success', summary: 'Success', detail: 'XML copied to clipboard', life: 3000 });
        })
        .catch(err => {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to copy XML to clipboard', life: 3000 });
        });
}
</script>