<template>
    <div class="text-2xl font-medium mb-5">{{ item.CODE }} - {{ item.DESCRIPTION }}</div>
    <div v-if="isLoading.item" style="width: calc(100vw - 450px); height: calc(100vh - 200px)"
        class="flex justify-content-center align-items-center">
        <i class="pi pi-spin pi-spinner" style="font-size: 4rem"></i>
    </div>
    <TabView v-else>

        <TabPanel header="Basic">
            <div class="card">
                <div class="flex w-12 gap-3">
                    <div class="w-6 gap-3 flex flex-column">
                        <div>
                            <div>
                                {{ item.DESCRIPTION }}
                            </div>

                            <div class="mt-5 mb-2 text-lg font-semibold">Stock Group: </div>
                            {{ item.STOCKGROUP }}

                            <div class="mt-5 mb-2 text-lg font-semibold">Suppliers: </div>
                            <!-- pluck COMPANYNAME from suppliers then v-for -->
                            <div v-for="supplier in item.suppliers" :key="supplier.id">
                                {{ supplier.COMPANYNAME }}
                            </div>

                            <div v-if="item.extended_fields?.picture_url">
                                <div class="mt-5 mb-2 text-lg font-semibold">Picture: </div>
                                <i v-if="isLoading.picture" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                                <img :src="backendBaseUrl + item.extended_fields?.picture_url" alt=""
                                    style="max-width: 100%;" @load="isLoading.picture = false"
                                    :class="isLoading.picture ? 'hidden' : ''">
                            </div>

                        </div>
                        <!-- Remark -->
                        <div>
                            <div class="card">
                                <div class="text-lg font-semibold">Remarks</div>

                                <div v-if="remarks.length == 0" class="mt-3">No remarks yet.</div>
                                <div v-else>
                                    <div v-for="remark in remarks"
                                        class="mt-3 bg-blue-50 py-3 px-2 border-round relative" :key="remark.id">
                                        <div class="flex justify-content-between">
                                            <div class="font-semibold">{{ remark.date }}</div>
                                            <div class="font-semibold text-sm"> ({{ remark.author }})</div>
                                        </div>
                                        <div class="flex justify-content-between">
                                            <div class="mt-2">{{ remark.content }}</div>
                                            <Button class="" text severity="danger"
                                                @click="deleteModal.show = true; deleteModal.remarkId = remark.id">Delete</Button>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-5 flex flex-column">
                                    <div class="font-semibold mb-1">Date</div>
                                    <Calendar v-model="inputs.remarkDate" />
                                    <div class="font-semibold mt-3 mb-1">Content</div>
                                    <Textarea v-model="inputs.remarkContent" rows="5" cols="30" />
                                    <Button class="mt-3 flex align-center  justify-content-center" @click="submitRemark"
                                        :disabled="inputs.remarkContent == ''">Submit</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ItemMeasurement :initial_measurement="item.default_measurement"
                        :measurements="item.measurements" class="w-6"></ItemMeasurement>

                </div>
            </div>
        </TabPanel>
        <TabPanel header="Purchases">
            <DataTable :value="purchases" responsiveLayout="scroll" scrollable scrollHeight="calc(85vh - 180px)"
                :loading="isLoading.purchases">

                <template #header>
                    <div class="text-lg font-semibold">Purchases</div>
                </template>
                <Column header="Date" field="DELIVERYDATE"></Column>
                <Column header="Quantity" field="SQTY"></Column>
                <Column header="Unit Total Cost (Final)" field="unit_cost_price"></Column>
                <Column header="Unit Landing Cost" field="unit_landing_cost"></Column>
                <Column header="UNITPRICE" field="UNITPRICE"></Column>
                <Column header="DISC" field="DISC"></Column>
                <Column header="LANDINGCOST1" field="LANDINGCOST1"></Column>
                <Column header="LANDINGCOST2" field="LANDINGCOST2"></Column>
            </DataTable>
        </TabPanel>
        <TabPanel header="Analysis">
            <!-- Sales Chart -->
            <div class="mt-6 card">
                <apexchart type="line" height="500" :options="sales_chart.chartOptions" :series="sales_chart.series">
                </apexchart>
            </div>


            <!-- Sales Chart YOY -->
            <div class="mt-6 card">
                <apexchart type="line" height="500" :options="sales_chart_yoy.chartOptions"
                    :series="sales_chart_yoy.series">
                </apexchart>
            </div>

            <!-- Transactions -->
            <div class="mt-6 card">
                <DataTable :value="transactions.data" responsiveLayout="scroll" @sort="onSortTransactions"
                    sortMode="multiple" lazy scrollable scrollHeight="450px" :loading="isLoading.transactions">

                    <template #header>
                        <div class="text-lg font-semibold">Transactions</div>
                    </template>

                    <Column header="Post Date" field="POSTDATE" :sortable="true"></Column>
                    <Column header="Time" field="DOCDATETIME"></Column>
                    <Column header="Doc No" field="DOCNO" :sortable="true"></Column>
                    <Column header="Doc Type" field="DOCTYPE" :sortable="true"></Column>
                    <Column header="Qty" field="QTY" :sortable="true"></Column>
                    <Column header="UTD. Qty" field="UTDQTY" :sortable="true"></Column>
                    <Column header="Cost" field="COST" :sortable="true"></Column>
                    <Column header="Price" field="PRICE" :sortable="true"></Column>

                    <template #footer>
                        <Paginator :rows="transactions.per_page" :totalRecords="transactions.total"
                            @page="onPageChangeTransactions($event)"
                            :first="(transactions.current_page - 1) * transactions.per_page">
                        </Paginator>
                    </template>
                </DataTable>
            </div>
        </TabPanel>
    </TabView>

    <!-- Remove Remark Dialog -->
    <Dialog v-model:visible="deleteModal.show" dismissableMask modal header="Remove remark"
        :style="{ 'max-width': '850px' }">
        <p>
            Are you sure you want to remove? Removed remark CANNOT be recovered.
        </p>

        <template #footer>
            <Button label="Don't Remove" icon="pi pi-times" @click="deleteModal.show = false" text />
            <Button label="Confirm Remove" icon="pi pi-check" @click="deleteRemark" autofocus />
        </template>
    </Dialog>
</template>

<script setup>
import moment from 'moment';
import axios from "axios";
import ItemMeasurement from '../../components/Item/ItemMeasurement.vue';
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const backendBaseUrl = process.env.VUE_APP_BACKEND_BASE_URL;

const item = ref({});
const transactions = ref([]);
const remarks = ref([]);
const inputs = ref({
    remarkDate: new Date(),
    remarkContent: "",
    measurement: {
        isEditing: false,
        volumePerItem: null,
        quantityPerCarton: null,
        cartonDimensions: {
            length: null,
            width: null,
            height: null,
        },
    },
});
const deleteModal = ref({
    remarkId: null,
    show: false,
});

const query = ref({
    transactionPage: null,
    transactionSortBy: null,
});

const isLoading = ref({
    item: false,
    transactions: false,
    purchases: false,
    picture: true,
});

const sales_chart_data_converted = ref({});

const sales_chart = ref({
    series: [],
    chartOptions: {
        chart: {
            height: 350,
            type: 'line',
        },
        stroke: {
            width: [0, 2],
            curve: 'stepline',
        },
        title: {
            text: 'Sales Chart'
        },
        xaxis: {
            type: 'datetime'
        },
        yaxis: [{
            title: {
                text: 'Qty Sold',
            },
            min: 0,
        }, {
            opposite: true,
            title: {
                text: 'Last Qty'
            },
            min: 0,
        }]
    },
});

const sales_chart_yoy = ref({
    series: [],
    chartOptions: {
        colors: ['#4E606B', '#D81B60', '#546E7A', '#E91E63'],
        chart: {
            height: 450,
            type: 'line',
        },
        stroke: {
            width: [0, 0, 4, 4],
            curve: 'straight',
        },
        title: {
            text: 'Sales Chart (YoY Comparison)'
        },
        labels: [],
        xaxis: {
            type: 'category'
        },
        yaxis: [
            {
                seriesName: 'Qty Sold',
                show: false
            },
            {
                seriesName: 'Qty Sold',
                title: {
                    text: 'Qty Sold',
                },
                min: 0,
            },
            {
                seriesName: 'Last Qty',
                show: false
            },
            {
                seriesName: 'Last Qty',
                opposite: true,
                title: {
                    text: 'Last Qty'
                },
                min: 0,
            },
        ]
    },
});

const getItem = async () => {
    isLoading.value.item = true;
    await axios({
        method: "GET",
        url: "items/" + route.params.code,
    }).then(
        (result) => {
            item.value = result.data.item;
            remarks.value = result.data.remarks;

            // Check if extended_fields is not null,
            if (item.value.extended_fields != null) {

                inputs.value.measurement = {
                    isEditing: false,
                    quantityPerCarton: item.value.extended_fields.qty_per_carton,
                    cartonDimensions: {
                        length: item.value.extended_fields.carton_length_cm,
                        width: item.value.extended_fields.carton_width_cm,
                        height: item.value.extended_fields.carton_height_cm,
                    },
                }
            }

            isLoading.value.item = false;
            document.title = 'KM - ' + item.value.DESCRIPTION;
        },
        (error) => {
            console.log(error.response.data);
        }
    );
}

getItem();

onMounted(() => {
    getPurchases();
    getTransactions();
    getSalesChartData();
});

const submitRemark = async () => {
    await axios.post("/items/" + route.params.code + "/remarks", {
        date: inputs.value.remarkDate.toISOString().split('T')[0],
        content: inputs.value.remarkContent,
    }).then(
        (result) => {
            remarks.value = result.data.remarks;
            inputs.value.remarkContent = "";
        },
        (error) => {
            console.log(error.response);
        }
    )
}

const deleteRemark = async () => {
    const id = deleteModal.value.remarkId;
    await axios.delete("/items/" + route.params.code + "/remarks/" + id).then(
        (result) => {
            remarks.value = result.data.remarks;
            deleteModal.value.show = false;
        },
        (error) => {
            console.log(error.response);
        }
    )
}

const getSalesChartData = async () => {
    await axios({
        method: "GET",
        url: "/items/" + route.params.code + "/sales-chart-data",
    }).then(
        (result) => {
            const dataObject = {};

            // Iterate through data 
            result.data.inventory_data.forEach((item) => {
                dataObject[item.POSTDATE] = {
                    LASTQTY: item.LASTQTY,
                    QTYSOLD: 0,
                }
            });

            // Iterate through data
            result.data.sales_data.forEach((item) => {
                if (dataObject[item.POSTDATE]) {
                    dataObject[item.POSTDATE].QTYSOLD = item.QTYSOLD;
                }
            });

            sales_chart_data_converted.value = dataObject;

            // -------------------------------------- Process sales_chart -------------------------------------- //
            // Convert dataObject to array
            const dates = [];
            const qtysold = [];
            const lastqty = [];
            for (const key in dataObject) {
                const element = dataObject[key];
                dates.push(key);
                qtysold.push(element.QTYSOLD);
                lastqty.push(element.LASTQTY);
            }



            sales_chart.value.series = [
                {
                    name: 'Qty Sold',
                    type: 'column',
                    data: qtysold
                },
                {
                    name: 'Last Qty',
                    type: 'line',
                    data: lastqty
                }
            ];

            sales_chart.value.chartOptions = {
                ...sales_chart.value.chartOptions,
                labels: dates,
            }

            // -------------------------------------- Process sales_chart_yoy -------------------------------------- //
            // Group data by month
            const dataObjectGroupedByMonth = {};
            for (const key in dataObject) {
                const element = dataObject[key];
                const month = moment(key).format('YYYY-MM');
                if (!dataObjectGroupedByMonth[month]) {
                    dataObjectGroupedByMonth[month] = {
                        QTYSOLD: 0,
                        LASTQTY: 0,
                    }
                }
                dataObjectGroupedByMonth[month].QTYSOLD += parseInt(element.QTYSOLD);

                // Get min of lastqty
                dataObjectGroupedByMonth[month].LASTQTY = parseInt(element.LASTQTY);
            }

            // Count back 12 months from today and get the month
            const today = moment();
            const last12months = [];
            for (let i = 0; i < 12; i++) {
                last12months.push(today.subtract(1, 'months').format('YYYY-MM'));
            }
            const previous13to24months = [];
            for (let i = 0; i < 12; i++) {
                previous13to24months.push(today.subtract(1, 'months').format('YYYY-MM'));
            }
            last12months.reverse();
            previous13to24months.reverse();

            // User the months without year as labels
            sales_chart_yoy.value.chartOptions.labels = last12months.map((month) => {
                return moment(month).format('MMM');
            });

            sales_chart_yoy.value.chartOptions = {
                ...sales_chart_yoy.value.chartOptions,
            }

            // Convert dataObjectGroupedByMonth to array for last12months only
            const qtysoldYoy = [];
            const lastqtyYoy = [];
            for (const month of last12months) {
                if (!dataObjectGroupedByMonth[month]) {
                    qtysoldYoy.push(0);
                    lastqtyYoy.push(0);
                }
                else {
                    qtysoldYoy.push(dataObjectGroupedByMonth[month].QTYSOLD);
                    lastqtyYoy.push(dataObjectGroupedByMonth[month].LASTQTY);
                }
            }

            const qtysoldYoyPreviousYear = [];
            const lastqtyYoyPreviousYear = [];

            for (const month of previous13to24months) {
                if (!dataObjectGroupedByMonth[month]) {
                    qtysoldYoyPreviousYear.push(null);
                    lastqtyYoyPreviousYear.push(null);
                }
                else {
                    qtysoldYoyPreviousYear.push(dataObjectGroupedByMonth[month].QTYSOLD);
                    lastqtyYoyPreviousYear.push(dataObjectGroupedByMonth[month].LASTQTY);
                }
            }

            sales_chart_yoy.value.series = [
                {
                    name: 'Qty Sold (Previous Year)',
                    type: 'column',
                    data: qtysoldYoyPreviousYear,
                },
                {
                    name: 'Qty Sold',
                    type: 'column',
                    data: qtysoldYoy
                },
                {
                    name: 'Last Qty (Previous Year)',
                    type: 'line',
                    data: lastqtyYoyPreviousYear
                },

                {
                    name: 'Last Qty',
                    type: 'line',
                    data: lastqtyYoy
                }
            ];
        },
        (error) => {
            console.log(error.response.data);
        }
    );
}

const getTransactions = async () => {
    isLoading.value.transactions = true;
    const params = new URLSearchParams();

    if (query.value.transactionSortBy) {
        params.append("sortBy", query.value.transactionSortBy);
    }

    if (query.value.transactionPage) {
        params.append("page", query.value.transactionPage);
    }

    await axios({
        method: "GET",
        url: "/items/" + route.params.code + "/transactions",
        params: params,
    }).then(
        (result) => {
            transactions.value = result.data.transactions;

            // Iterate over transacations.data and change qty to integer
            transactions.value.data.forEach((transaction) => {
                transaction.QTY = parseInt(transaction.QTY);
                transaction.UTDQTY = transaction.UTDQTY ? parseInt(transaction.UTDQTY) : '';

                transaction.COST = parseFloat(transaction.COST);
                transaction.PRICE = parseFloat(transaction.PRICE).toFixed(2);

                // Convert synced_at to DD-MMM HH:MM AM/PM
                transaction.DOCDATETIME = transaction.DOCDATETIME ? moment(String(transaction.DOCDATETIME)).format('DD-MMM, hh:mm A') : ''
            });
        },
        (error) => {
            console.log(error.response.data);
        }
    );
    isLoading.value.transactions = false;
}


const purchases = ref([]);

const getPurchases = async () => {
    isLoading.value.purchases = true;
    const params = new URLSearchParams();

    // if (query.value.transactionSortBy) {
    //     params.append("sortBy", query.value.transactionSortBy);
    // }

    // if (query.value.transactionPage) {
    //     params.append("page", query.value.transactionPage);
    // }

    await axios({
        method: "GET",
        url: "/items/" + route.params.code + "/purchases",
        params: params,
    }).then(
        (result) => {
            purchases.value = result.data.purchases;
        },
        (error) => {
            console.log(error.response.data);
        }
    );
    isLoading.value.purchases = false;
}

const onSortTransactions = ($event) => {
    query.value.transactionPage = 1;
    // Pluck field string from multiSortMeta
    const sortBy = $event.multiSortMeta.map((item) => {
        const order = item.order === 1 ? "asc" : "desc";
        return item.field + "-" + order;
    });

    query.value.transactionSortBy = sortBy.join(",");
    router.push({ query: { ...query.value }, params: { savedPosition: true } });
    getTransactions();
}

const onPageChangeTransactions = ($event) => {
    query.value.transactionPage = $event.page + 1;
    router.push({ query: { ...query.value }, params: { savedPosition: true } });
    getTransactions();
}
</script>

<style scoped></style>
