<template>
    <div class="flex gap-3">
        <div class="text-4xl font-medium">Refill Groups</div>
        <Button label="New" @click="showAddNewRerfillGroupDialog"></Button>
    </div>

    <div class="flex gap-3 align-items-center mt-5">
        <div class="text-lg font-medium">Show Inactive Groups </div>
        <InputSwitch v-model="filters.showInactiveGroups" @change="getRefillGroups" />
    </div>

    <!-- Data Table -->
    <DataTable ref="dt" :value="refillGroupsFiltered" responsiveLayout="scroll" class="mt-6 p-datatable-sm"
        :loading="isLoading" stripedRows sortMode="multiple" scrollable dataKey="id" scrollHeight="750px" size="small"
        var="line">

        <template #header>
            <div class="flex justify-content-left flex-column sm:flex-row">
                <IconField class="mb-2">
                    <InputIcon class="pi pi-search" />
                    <InputText v-model="searchField" placeholder="Search" style="width: 100%" @keydown="textChanged" />
                </IconField>
            </div>
        </template>

        <Column field="name" header="Name" :sortable="true" headerStyle="width:15rem"> </Column>
        <Column field="description" header="Description" :sortable="true"> </Column>
        <Column field="team_name" header="Team" :sortable="true"> </Column>
        <Column field="count_items" header="No. Of Items" :sortable="true"> </Column>
        <Column field="is_active" header="Status" :sortable="true" headerStyle="width:15rem">
            <template #body="{ data }">
                <div v-if="data.is_active" class="">Active</div>
                <div v-else class="text-red-600 font-medium">Inactive</div>
            </template>

        </Column>
        <Column field="last_print_at" header="Last Print" :sortable="true">
            <template #body="{ data }">
                <div v-if="data.last_print_at">
                    <div
                        :class="moment().isSame(moment(data.last_print_at), 'day') ? 'text-green-700 font-medium' : ''">
                        {{ moment(data.last_print_at).fromNow() }}</div>
                </div>
            </template>
        </Column>
        <Column header="Actions" :sortable="false" headerStyle="width:25rem">
            <template #body="{ data }">
                <div class="flex gap-3">
                    <router-link :to="'/refill-groups/' + data.id">
                        <Button icon="pi pi-cog" raised style="height: 30px;" />
                    </router-link>
                    <RefillPrintButtonMenu :refill_group_id="data.id" @refillGroupPrinted="refillGroupPrinted">
                    </RefillPrintButtonMenu>
                </div>
            </template>
        </Column>
    </DataTable>


    <!-- Show Add New Dialog -->
    <Dialog v-model:visible="dialogAddNewRefillGroup.show" modal header="Add New Refill Group"
        :style="{ width: '45rem' }">

        <div class="mt-2 mb-2 text-lg font-medium">Team</div>
        <Dropdown v-model="dialogAddNewRefillGroup.selectedTeam" :options="dialogAddNewRefillGroup.teams"
            optionLabel="name" placeholder="Select a team" />

        <div class="mt-3 mb-2 text-lg font-medium">Group Name</div>
        <InputText v-model="dialogAddNewRefillGroup.name" type="text" />

        <div class="mt-3 mb-2 text-lg font-medium">Group Description</div>
        <InputText v-model="dialogAddNewRefillGroup.description" type="text" class="mb-4" />

        <!-- Error message -->
        <div v-if="dialogAddNewRefillGroup.errorMessage" class="mt-2 text-red-600">{{
            dialogAddNewRefillGroup.errorMessage
        }}</div>

        <div class="flex gap-3 w-100 justify-content-end">
            <Button label="Cancel" class="p-button-raised" severity="secondary"
                @click="dialogAddNewRefillGroup.show = false" :loading="dialogAddNewRefillGroup.isLoading" />
            <Button label="Save" class="p-button-raised" @click="saveDialogAddNewRefillGroup"
                :loading="dialogAddNewRefillGroup.isLoading" />
        </div>
    </Dialog>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios';
import moment from 'moment';
import RefillPrintButtonMenu from '@/components/Refill/RefillPrintButtonMenu.vue';
// import { useRoute, useRouter } from 'vue-router';

const refillGroups = ref([]);
const refillGroupsFiltered = ref([]);
const isLoading = ref(false);

const filters = ref({
    showInactiveGroups: false,
});

const getRefillGroups = async () => {
    isLoading.value = true;

    await axios({
        method: "GET",
        url: "refill-groups",
    }).then(
        (result) => {
            refillGroups.value = result.data.refill_groups;

            if (!filters.value.showInactiveGroups) {
                refillGroupsFiltered.value = refillGroups.value.filter((group) => group.is_active);
            }
        },
        (error) => {
            isLoading.value = false;
            console.log("ERROR GETTING REFILL GROUPS");
            console.log(error.response.data);
        }
    );
    isLoading.value = false;
}

// --------------- Add New Refill Group Dialog ---------------

const dialogAddNewRefillGroup = ref({
    isLoading: false,
    show: false,
    name: "",
    description: "",
});

const showAddNewRerfillGroupDialog = () => {
    dialogAddNewRefillGroup.value = {
        show: true,
        name: "",
        description: "",
        errorMessage: "",
        teams: [],
        selectedTeam: null,
    };

    // Get teams
    axios({
        method: "GET",
        url: "teams",
    }).then(
        (result) => {
            dialogAddNewRefillGroup.value.teams = result.data.teams;
        },
        (error) => {
            console.log("ERROR GETTING TEAMS");
            console.log(error.response.data);
        }
    );
}

const saveDialogAddNewRefillGroup = async () => {
    dialogAddNewRefillGroup.value.isLoading = true;
    dialogAddNewRefillGroup.value.errorMessage = "";

    if (dialogAddNewRefillGroup.value.selectedTeam == null) {
        dialogAddNewRefillGroup.value.errorMessage = "Please select a team";
        dialogAddNewRefillGroup.value.isLoading = false;
        return;
    }

    await axios({
        method: "POST",
        url: "refill-groups",
        data: {
            name: dialogAddNewRefillGroup.value.name,
            description: dialogAddNewRefillGroup.value.description,
            team_id: dialogAddNewRefillGroup.value.selectedTeam.id,
        },
    }).then(
        () => {
            dialogAddNewRefillGroup.value.show = false;
            getRefillGroups();
        },
        (error) => {
            dialogAddNewRefillGroup.value.isLoading = false;
            dialogAddNewRefillGroup.value.errorMessage = error.response.data.message;
            console.log(error.response.data);
        }
    );

    dialogAddNewRefillGroup.value.isLoading = false;
}

const refillGroupPrinted = (refillGroupId) => {
    const refillGroup = refillGroups.value.find((group) => group.id == refillGroupId);
    if (refillGroup) {
        refillGroup.last_print_at = moment().format();
    }
}

// ---------------------------------------------------------

// Search field
const searchField = ref("");
const isSearching = ref(false);
let textInputTimer = null;

const textChanged = () => {
    clearTimeout(textInputTimer);
    textInputTimer = setTimeout(async () => {
        if (isSearching.value) {
            textChanged();
            return;
        }

        isSearching.value = true;
        await searchGroup();
        isSearching.value = false;
    }, 50);
};

const searchGroup = async () => {
    refillGroupsFiltered.value = refillGroups.value.filter((group) => {
        const searchWords = searchField.value.toLowerCase().split(" ");
        return searchWords.every((word) => group.name.toLowerCase().includes(word));
    });
};


getRefillGroups();
</script>

<style scoped></style>